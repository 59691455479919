import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import Button from '../../atoms/Buttons/Button/Button';
import { Heading2 } from '../../atoms/Headings/Headings';
import Card from '../../atoms/Card/Card';
import IconTextBlock from '../../molecules/IconTextBlock/IconTextBlock';
import {
  breakpoints,
  colors,
  fontSizes,
  headingColors,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import Section from '../../molecules/Section/Section';

const GradientCard = ({
  _key,
  button,
  headingText,
  iconTextBlocks,
  sectionStyles,
  suiteType,
}) => {
  const gradientCardCss = css`
    width: ${pageDimensions.desktopWidth};
    max-width: 100%;
    margin: 0 auto;
    padding: ${uc('75px 0 80px')};
    text-align: center;
    background-image: linear-gradient(
      to right,
      ${colors[suiteType].gradient.start},
      ${colors[suiteType].gradient.end}
    );

    @media (${breakpoints.desktop}) {
      max-width: calc(100% - ${uc('40px')});
      padding: ${uc('75px 20px 80px')};
    }

    @media (${breakpoints.tablet}) {
      padding: ${uc('35px 20px')};
    }
  `;

  const iconTextBlocksGroupCss = css`
    margin: ${uc('60px')};

    .iconTextBlock {
      margin: ${uc('0 44px')};

      h4 {
        margin-bottom: ${uc('15px')};
        color: ${headingColors.white};
      }

      p {
        color: ${headingColors.white};
        font-size: ${fontSizes.sixteen};
      }

      div {
        margin: ${uc('20px auto 25px')};
      }
    }

    @media (${breakpoints.desktop}) {
      margin: ${uc('20px')};

      .iconTextBlock {
        margin: ${uc('0 20px')};
      }
    }

    @media (${breakpoints.largeTablet}) {
      margin: auto;

      .iconTextBlock {
        max-width: 90%;
        margin: ${uc('0 10px')};
      }
    }
  `;

  const renderedIconTextBlocks = iconTextBlocks.map(iconTextBlock => (
    <IconTextBlock
      key={`icontextblock-component-${iconTextBlock._key}`}
      className="iconTextBlock"
      {...iconTextBlock}
    />
  ));

  return (
    <Section sectionStyles={sectionStyles}>
      <Card css={gradientCardCss}>
        <Heading2 color={colors.white} fontSize={fontSizes.thirty}>
          {headingText}
        </Heading2>
        <div css={iconTextBlocksGroupCss}>{renderedIconTextBlocks}</div>
        {button && button.text && (
          <Button _key={`gradient-${_key}`} {...button}>
            {button.text}
          </Button>
        )}
      </Card>
    </Section>
  );
};

GradientCard.propTypes = {
  _key: PropTypes.string,
  headingText: PropTypes.string.isRequired,
  suiteType: PropTypes.string.isRequired,
  iconTextBlocks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  button: PropTypes.shape({ text: PropTypes.string }),
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

GradientCard.defaultProps = {
  _key: '',
  button: {},
  sectionStyles: {},
};

export default GradientCard;
